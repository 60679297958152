import types from '../store/_types'
import store from '../store'

export default {
  url: '/api/v1',

  /**
   * fetch() will only reject a promise if the user is offline,
   * or some unlikely networking error occurs, such a DNS lookup failure.
   * However, there is a simple `ok` flag that indicates
   * whether an HTTP response's status code is in the successful range.
   */
  async _handleResponse(response) {
    let json = {}
    try {
      json = await response.json()
    } catch (error) {
      console.log(error)
    }

    if (response.ok) {
      return json
    } else {
      return Promise.reject({
        result: json,
        status: response.status,
        statusText: response.statusText,
        message:
          json.detail ||
          json.error ||
          json.message ||
          'Unexpected error occurred',
      })
    }
  },

  _pointer(_endpoint) {
    return `${this.url}${_endpoint}`
  },

  async _request(_method, _endpoint, _body) {
    if (_method !== 'GET' && !store.state.CSRFToken) await this.setCSRF()

    if (_method === 'GET' && _body !== undefined) {
      _endpoint = _endpoint + '?' + new URLSearchParams(_body)
    }

    const data = {
      method: _method,
      credentials: 'same-origin',
      mode: 'same-origin',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-CSRFToken': store.state.CSRFToken,
      }),
      body: _body && _method !== 'GET' ? JSON.stringify(_body) : null,
    }

    const response = await fetch(this._pointer(_endpoint), data)
    return await this._handleResponse(response)
  },

  /**
   * Get abstraction.
   * @return {Promise || json}
   */
  async get(_endpoint, _body) {
    return await this._request('GET', _endpoint, _body)
  },

  /**
   * Post abstraction.
   * @return {Promise || json}
   */
  async post(_endpoint, _body) {
    return await this._request('POST', _endpoint, _body)
  },
  async patch(_endpoint, _body) {
    return await this._request('PATCH', _endpoint, _body)
  },
  async put(_endpoint, _body) {
    return await this._request('PUT', _endpoint, _body)
  },
  async delete(_endpoint, _body = null) {
    return await this._request('DELETE', _endpoint, _body)
  },
  async setCSRF() {
    const response = await fetch(this._pointer('/users/csrf-token/'))
    const csrfToken = response.headers.get('X-CSRFToken')
    await store.dispatch(types.SET_CSRF_TOKEN, csrfToken)
  },
  async getSessionInfo() {
    try {
      const data = await this.get('/users/session/')
      await store.dispatch(types.SET_IS_AUTHENTICATED, true)
      await store.dispatch(types.SET_USER, data)
      return data
    } catch (error) {
      await store.dispatch(types.LOG_OUT)
    }
    if (!store.state.CSRFToken) {
      await this.setCSRF()
    }
  },
  async logOut() {
    try {
      const data = await this.post('/users/logout/')
      if (data.result === true) {
        await store.dispatch(types.SET_IS_AUTHENTICATED, false)
        await store.dispatch(types.SET_USER, null)
      }
      return true
    } catch (error) {
      return false
    }
  },
}
