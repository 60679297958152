import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueGtag from 'vue-gtag'

const app = createApp(App)

const toastOptions = {}

app.use(store)
app.use(router)
app.use(Toast, toastOptions)
app.use(
  VueGtag,
  {
    appName: 'moss.chat',
    pageTrackerScreenviewEnabled: true,
    config: { id: 'G-3FE69LNX29' },
    pageTrackerExcludedRoutes: ['privacy', 'terms'],
  },
  router
)
app.mount('#app')
