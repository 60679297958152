<template>
  <div
    class="modal"
    @click.self="hide"
    :style="{ display: show && !hidden ? 'grid' : 'none' }"
  >
    <!-- Modal content -->
    <div class="modal-content">
      <div class="modal-header">
        <div class="header-text">
          <slot name="header">
            <h3>{{ title }}</h3>
          </slot>
        </div>
        <div class="close centered" @click="hide">
          <i class="bi bi-x-lg"></i>
        </div>
      </div>
      <div class="modal-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    isVisible() {
      return this.show
    },
  },
  data() {
    return {
      hidden: false,
    }
  },
  methods: {
    hide() {
      this.$emit('modal-hide')
    },
  },
}
</script>

<style lang="scss" scoped>
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  padding: 0 1rem;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  align-self: center;
  justify-self: center;
  position: relative;
  background-color: #fefefe;
  padding: 0;
  border: 1px solid #888;
  border-radius: 0.5rem;
  overflow: hidden;
  max-width: 30rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* Modal Header */
.modal-header {
  display: grid;
  align-items: center;
  grid-template-columns: 7fr 1fr;
  padding: 0 0 0 16px;
  background-color: $alternativeBorderColor;
  color: $textColor;

  h3 {
    margin: 0.4rem 0;
  }
}

/* Modal Body */
.modal-body {
  padding: 2px 16px;
}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Add Animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
</style>
