<template>
  <div id="main-content">
    <Navbar />
    <div class="view">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import Footer from './components/Footer'
export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&display=swap');
@import '~bootstrap-icons/font/bootstrap-icons.css';

html,
body {
  // font-family: 'Montserrat', sans-serif;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

body {
  // font-size: 14px;
  font-size: 0.9rem;
  box-sizing: border-box;
  // background-color: #fff6e6;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#main-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
}

i.bi {
  font-size: 1.2rem;

  &.bi-big {
    font-size: 1.4rem;
  }
  &.bi-small {
    font-size: 1rem;
  }
}

.alert {
  display: inline-block;
  border: 1px solid;
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin: 0.5rem 0;

  &.info {
    border-color: #c5cefe;
    background: #e2f3ff;
  }

  &.error {
    border-color: #ffb9b9;
    background: #ffdbdb;
  }

  &.warning {
    border-color: #ffd883;
    background: #ffefce;
  }

  &.success {
    border-color: #bcffac;
    background: #deffe2;
  }
}

.noselect {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.select-all {
  user-select: all;
  -moz-user-select: all;
  -webkit-user-select: all;
}

.selectable {
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block {
  display: block;
}

.centered {
  text-align: center;
}

.my-2 {
  margin: 0.5rem 0;
}

.my-3 {
  margin: 0.7rem 0;
}

.mx-2 {
  margin: 0 0.5rem;
}

.pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.shadow {
  box-shadow: $boxShadow;
}

a.join-btn {
  display: block;
  align-self: flex-start;
  justify-self: center;
  background: linear-gradient(
    360deg,
    $alternativeAccentColor,
    $secondaryAccentColor
  );
  padding: 0.5rem 2rem;
  border-radius: 0.3rem;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  transition: all 0.3s;
}

a.join-btn:hover {
  opacity: 0.8;
}

.Vue-Toastification__toast {
  min-height: 40px !important;
  padding: 15px !important;
}
</style>
