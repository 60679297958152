const priceToStr = (price) => {
  let intervalStr = price.interval[0]
  if (price.interval_count > 1) {
    intervalStr = `$${price.amount / price.interval_count / 100}/${
      price.interval[0]
    } `
    if (price.interval === 'month') {
      switch (price.interval_count) {
        case 3:
          intervalStr += 'paid quarterly'
          break
        case 6:
          intervalStr += 'paid biannually'
          break
        case 12:
          intervalStr += 'paid annually'
          break
        default:
          break
      }
    }
    return intervalStr
  }
  return `$${price.amount / 100}/${intervalStr}`
}

export { priceToStr }
