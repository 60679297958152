import { createAvatar } from '@dicebear/avatars'
import * as bottts from '@dicebear/avatars-bottts-sprites'
import * as pixelArt from '@dicebear/pixel-art'

const sprites = {
  bottts,
  pixelArt,
}
const createPhoto = (seed, type = 'pixelArt') => {
  let svg = createAvatar(sprites[type], {
    seed: seed,
    // ... and other options
  })
  return svg
}

export { createPhoto }
