<template>
  <div v-if="message" class="centered">
    <div class="alert info">{{ message }}</div>
  </div>
  <router-view />
</template>

<script>
export default {
  data() {
    return {
      message: '',
    }
  },
  methods: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
select {
  padding: 0.3rem;
  margin: 1rem 0;
}
</style>
