<template>
  <div class="agent">
    <div v-if="!agent.photo">
      <div class="image" v-html="createPhoto(agent.telegram_id)"></div>
    </div>
    <div v-else class="image">
      <img :src="agent.photo" />
    </div>
    <div class="name">
      <h4 class="ellipsis">{{ agent.full_name }}</h4>
    </div>
    <div
      v-if="
        isCreator && agent.telegram_id !== activeProject.creator_telegram_id
      "
      class="remove pointer"
      @click="removeAgent(agent)"
    >
      <i class="bi bi-x-lg"></i>
    </div>
  </div>
</template>

<script>
import { createPhoto } from '@/utils/chatUtils.js'
import { mapGetters } from 'vuex'
import api from '../api'
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  props: {
    agent: {
      type: Object,
      default: () => Object(),
    },
  },
  computed: {
    ...mapGetters('chat', ['activeProject', 'isCreator']),
  },
  methods: {
    createPhoto,
    async removeAgent(agent) {
      const message = `${agent.full_name} will be removed. Are you sure?`
      if (!confirm(message)) return
      try {
        const result = await api.delete(
          `/projects/${this.activeProject.id}/agents/${agent.telegram_id}`
        )
        if (result) {
          this.$emit('removeAgent', agent)
          this.toast.success(
            `You have successfully removed ${agent.full_name}.`
          )
        }
      } catch (data) {
        this.toast.error(data.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.agent {
  display: grid;
  align-items: center;
  grid-template-columns: 40px auto 30px;
  border: 0.1rem solid $alternativeBorderColor;
  background-color: $backgroundColor;
  border-radius: 0.5rem;
  padding: 0.5rem;

  .name {
    overflow: hidden;
    h4 {
      margin: 0;
    }
  }

  .image,
  .image img {
    width: 30px;
    height: 30px;
    border-radius: 0.3rem;
  }

  .remove img {
    width: 25px;
    height: 25px;
  }
}
</style>
