<template>
  <div>
    <p>
      Here you can see your subscription plans and products, or available ones
      to buy.
    </p>
    <h3>Active subscriptions</h3>
    <template v-if="subscribedProducts.length">
      <product-box
        :key="product.id"
        v-for="product in subscribedProducts"
        :product="product"
        :showBuyButton="false"
        :activeProduct="true"
        :subscription="getSubscriptionOfProduct(product)"
      />
    </template>
    <template v-else>
      <div>
        <p>You do not currently have any subscriptions.</p>
      </div>
    </template>
    <div v-if="isCustomer || subscribedProducts.length">
      <button @click="createPortalSession">Manage subscriptions</button>
    </div>

    <template v-if="availableProductsToSubscribe.length">
      <h2>
        <i class="bi bi-shop"></i>
        Marketplace
      </h2>

      <product-box
        :key="product.id"
        v-for="product in availableProductsToSubscribe"
        :product="product"
        :subscribed="isProductSubscribed(product)"
        :subscribed-parents="isAnyOfProductsSubscribed(product.parent_products)"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '../api'
import ProductBox from '../components/ProductBox.vue'

import { useToast } from 'vue-toastification'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  components: { ProductBox },
  data() {
    return {
      products: [],
      subscriptions: [],
    }
  },
  computed: {
    ...mapGetters('chat', ['activeProject']),
    projectId() {
      return this.$route.params.projectId ?? localStorage.getItem('projectId')
    },
    isCustomer() {
      return this.$store.state.user.is_customer
    },
    isCreator() {
      return (
        this.activeProject.creator_telegram_id ===
        this.$store.state.user.telegram_id
      )
    },
    availableProductsToSubscribe() {
      return this.products
        .filter((p) => this.subscriptions.every((s) => p.id !== s.product))
        .sort((a) => !a.is_mega)
    },
    subscribedProducts() {
      return this.products
        .filter((p) =>
          this.subscriptions.some((s) => {
            const eq = p.id === s.product
            if (eq) {
              p.quantity = s.quantity
            }
            return eq
          })
        )
        .sort((a) => !a.is_mega)
    },
  },
  methods: {
    isAnyOfProductsSubscribed(products) {
      return products.filter((p) => this.isProductSubscribed(p))
    },
    isProductSubscribed(product) {
      if (!product) return false
      return this.subscribedProducts.some((p) => p.id === product.id)
    },
    getSubscriptionOfProduct(product) {
      return this.subscriptions.find((s) => s.product === product.id)
    },
    async getSubscriptions(projectId) {
      try {
        this.subscriptions = await api.get(
          `/subscriptions/${projectId}/subscriptions/`
        )
      } catch (error) {
        console.log(error)
      }
    },
    async getProducts() {
      try {
        const products = await api.get('/subscriptions/products/')
        this.products = products
      } catch (data) {
        this.toast.error(data.message)
      }
    },
    async createPortalSession() {
      try {
        const r = await api.post('/subscriptions/portal-session/create/', {
          projectId: this.projectId,
        })
        window.location.href = r.portal_url
      } catch (data) {
        this.toast.error(data.message)
      }
    },
  },
  async mounted() {
    const projectId = this.projectId
    await this.getSubscriptions(projectId)
    await this.getProducts()
  },
}
</script>

<style lang="scss" scoped>
.header {
  margin: 0 0 0.5rem;
}
.content {
  p {
    margin: 0;
  }

  h4 {
    margin: 1rem 0 0.3rem;
  }
}
</style>
