<template>
  <div id="project-settings">
    <div class="content">
      <div class="settings">
        <div v-if="!isCreator" class="alert warning block">
          <i class="bi bi-info-circle"></i>
          <b class="mx-2">
            As a member, you have limited access to this project and its
            settings.
          </b>
        </div>
        <div class="row">
          <h2 class="header">
            <i class="bi bi-people-fill"></i> Team
            <span v-if="isCreator" class="pointer" @click="addAgentModal = true"
              ><i style="font-size: 1rem" class="bi bi-plus-square"></i
            ></span>
          </h2>

          <div class="agents">
            <agent
              :key="agent.telegram_id"
              v-for="agent in agents"
              :agent="agent"
              @remove-agent="removeAgent"
            />
            <p v-if="!agents.length">No agents found.</p>
          </div>
          <p>
            <b>Team quota:</b> {{ agents.length }}/{{
              activeProject.agent_limit
            }}
          </p>
          <!-- <p>
            Plus or Pro projects can have more than one agent in a team. An
            agent can use this chat screen to send and receive messages. They
            cannot access admin-only areas like Subscription section.
          </p> -->
          <modal
            :show="addAgentModal"
            @modal-hide="addAgentModal = false"
            title="How to add new agent"
          >
            <p>
              You need to create a private group for your team and add your
              project bot to the group. That's all.
            </p>
            <p>
              Once an agent from your private group logged in using the project
              login URL below, the agent will appear in the agents list. Don't
              worry, they can't access admin-only areas in this page.
            </p>
            <p>
              <b>The project login URL for agents:</b> <br />
              <code class="selectable">{{ projectUrl }}</code>
            </p>
          </modal>
        </div>

        <div v-if="isCreator" class="row">
          <h2 class="header">
            <i class="bi bi-chat-text"></i> Welcome Message
          </h2>
          <project-welcome-message />
        </div>

        <div class="row" v-if="isCreator">
          <h2 class="header"><i class="bi bi-basket2"></i> Subscriptions</h2>
          <payment-section />
        </div>

        <div class="row">
          <h2 class="header">
            <i class="bi bi-info-circle"></i> Project Information
          </h2>
          <project-information />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '../../api'
import Agent from '../../components/Agent.vue'
import Modal from '../../components/Modal.vue'

import { useToast } from 'vue-toastification'
import PaymentSection from '../PaymentSection.vue'
import ProjectWelcomeMessage from './ProjectWelcomeMessage.vue'
import ProjectInformation from './ProjectInformation.vue'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  components: {
    Agent,
    Modal,
    PaymentSection,
    ProjectWelcomeMessage,
    ProjectInformation,
  },
  data() {
    return {
      products: [],
      subscriptions: [],
      agents: [],
      addAgentModal: false,
    }
  },
  computed: {
    ...mapGetters('chat', ['activeProject', 'isCreator']),
    projectUrl() {
      if (!this.activeProject.id) return ''
      const route = {
        name: 'project-chat',
        params: { projectId: this.activeProject.id },
      }
      const protocol = window.location.protocol
      const host = window.location.host
      const projectPath = this.$router.resolve(route).href
      return `${protocol}//${host}${projectPath}`
    },
    isCustomer() {
      return this.$store.state.user.is_customer
    },
    isCreator() {
      return (
        this.activeProject.creator_telegram_id ===
        this.$store.state.user.telegram_id
      )
    },
    availableProductsToSubscribe() {
      return this.products
        .filter((p) => this.subscriptions.every((s) => p.id !== s.product))
        .sort((a) => !a.is_mega)
    },
    subscribedProducts() {
      return this.products
        .filter((p) =>
          this.subscriptions.some((s) => {
            const eq = p.id === s.product
            if (eq) {
              p.quantity = s.quantity
            }
            return eq
          })
        )
        .sort((a) => !a.is_mega)
    },
  },
  methods: {
    isProductAvailable(product) {
      if (!product) return true
      return (
        this.subscribedProducts.filter((p) => p.id === product.id).length <= 0
      )
    },
    async getAgents(projectId) {
      try {
        const r = await api.get(`/projects/${projectId}/agents/`)
        this.agents = r.results
      } catch (data) {
        this.toast.error(data.message)
      }
    },
    removeAgent(agent) {
      this.agents.splice(this.agents.indexOf(agent), 1)
    },
    async getSubscriptions(projectId) {
      try {
        this.subscriptions = await api.get(
          `/subscriptions/${projectId}/subscriptions/`
        )
      } catch (error) {
        console.log(error)
      }
    },
    async getProducts() {
      try {
        const products = await api.get('/subscriptions/products/')
        this.products = products
      } catch (data) {
        this.toast.error(data.message)
      }
    },
    async createPortalSession() {
      try {
        const r = await api.post('/subscriptions/portal-session/create/', {
          projectId: this.$route.params.projectId,
        })
        window.location.href = r.portal_url
      } catch (data) {
        this.toast.error(data.message)
      }
    },
  },
  async mounted() {
    const projectId = this.$route.params.projectId
    await this.getAgents(projectId)
    await this.getProducts()
    if (this.isCreator) await this.getSubscriptions(projectId)
  },
}
</script>

<style lang="scss" scoped>
#project-settings {
  .content {
    padding: 0.5rem 1rem;
    overflow-y: auto;
  }
}

.row {
  margin: 0 0 1.5rem;

  .header {
    margin: 0 0 0.5rem;
  }
  .content {
    p {
      margin: 0;
    }

    h4 {
      margin: 1rem 0 0.3rem;
    }
  }

  .agents {
    display: grid;
    grid-template-columns: 2fr 2fr;
  }

  @media screen and (max-width: 768px) {
    .agents {
      grid-template-columns: 1fr;
    }
  }
}
</style>
