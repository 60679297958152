import types from './_types'
import { generateUniversalId } from '@/utils/uuid'

export default {
  namespaced: true,
  state: () => ({
    text: '',
    disabled: false,
    placeholder: 'Type a message...',
    files: [],
  }),
  mutations: {
    [types.SET_TEXT](state, text) {
      state.text = text
    },
    [types.SET_FILES](state, files) {
      state.files = files
    },
    [types.SET_PLACEHOLDER](state, placeholderText) {
      state.placeholder = placeholderText
    },
  },
  actions: {
    async send({ state, rootState, commit, rootGetters }) {
      const text = state.text.trim()
      if (!text) return

      const activeChat = rootGetters['chat/activeChat']
      const telegramChatId = activeChat.telegram_chat_id
      const chatId = activeChat.id
      const message = {
        text: text,
        chat_id: chatId,
        files: state.files,
        telegram_chat_id: telegramChatId,
        is_incoming: false,
        temporary_id: generateUniversalId(),
        created_at: Date.now(),
      }
      rootState.chat.connection.send(
        JSON.stringify({
          type: 'chat.message',
          data: message,
        })
      )
      commit(types.SET_TEXT, '')
      commit('chat/' + types.ADD_CHAT_MESSAGES, { message }, { root: true })
    },
  },
  getters: {},
}
