import api from '.'

export async function getChatMessages(
  chatId,
  projectId,
  offset = 0,
  limit = 50
) {
  try {
    const uri = `/projects/${projectId}/messages/${chatId}/`
    const messages = await api.get(uri, {
      offset: offset,
      limit: limit,
    })
    return messages
  } catch (error) {
    console.log(error)
  }
}

export async function getChats(projectId) {
  const uri = `/projects/${projectId}/chats/`
  return await api.get(uri)
}
