<template>
  <div class="content">
    <!-- <div
      id="preview"
      v-if="preview"
      :style="{ 'background-image': `url(${preview})` }"
      @click.self="selectImage"
    >
      <span class="close-btn" @click="clear"> <i class="bi bi-x-lg"></i> </span>
    </div> -->
    <textarea
      class="textarea"
      rows="1"
      :placeholder="$store.state.chat.editor.placeholder"
      v-model="text"
      @keydown.enter.exact.prevent
      @keyup.enter.exact="send"
    >
    </textarea>
    <button @click="send" type="button"><i class="bi bi-send"></i></button>
    <!-- <div class="upload-icon" style="display: none">
      <label for="file-input"> <i class="bi bi-paperclip"></i> </label>
      <input id="file-input" ref="fileInput" type="file" @input="pickFile" />
    </div> -->
  </div>
</template>

<script>
import { SET_TEXT, SET_FILES } from '@/store/chat/_types'

export default {
  data() {
    return {
      preview: null,
    }
  },
  computed: {
    text: {
      get() {
        return this.$store.state.chat.editor.text
      },
      set(text) {
        this.$store.commit('chat/editor/' + SET_TEXT, text)
      },
    },
  },
  methods: {
    clear() {
      const input = this.$refs.fileInput
      input.value = null
      this.preview = null
    },
    send(event) {
      this.$store.dispatch('chat/editor/send', event)
    },
    selectImage() {
      this.$refs.fileInput.click()
    },
    pickFile() {
      const input = this.$refs.fileInput
      const inputFiles = input.files
      if (inputFiles && inputFiles[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          // TODO: IMPLEMENT FILE UPLOAD
          const files = [...this.$store.state.chat.editor.files]
          files.push(e.target.result)

          this.preview = e.target.result
          this.$store.commit('chat/editor/' + SET_FILES, files)
        }
        reader.readAsDataURL(inputFiles[0])
        console.log('input', inputFiles[0])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  display: grid;
  grid-template-columns: 1fr 50px;
  column-gap: 0.5rem;
  justify-content: space-around;
  position: relative;
  height: 100%;

  button {
    background: $backgroundColor;
    box-shadow: none;
    border: none;
    border: 1px solid $alternativeBorderColor;
    border-radius: 0.5rem;
    height: 100%;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: $alternativeBackgroundColor;
    }
    &:active {
      transform: translateY(2px);
    }
  }
}

.upload-icon {
  display: inline;
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;

  label {
    cursor: pointer;
  }
  input[type='file'] {
    display: none;
  }
}

#preview {
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 10px;
  background-size: cover;
  background-position: center center;
  overflow: hidden;

  .close-btn {
    background: white;
    border-radius: 5px;
    margin: 5px;
    display: inline-block;
    img {
      width: 25px;
      height: 25px;
      vertical-align: middle;
    }
  }
}

textarea {
  width: 100%;
  height: 100%;
  padding: 0.4rem;
  margin: 0;
  resize: vertical;
  font-size: 1rem;
  font-family: sans-serif;
  border-radius: 0.5rem;
  border: none;
}

textarea:focus {
  outline: none;
}
</style>
