<template>
  <div>
    <div class="block">
      <div class="left">Bot Telegram ID</div>
      <div class="right">{{ project.bot_telegram_id }}</div>
    </div>
    <div class="block">
      <div class="left">Bot name</div>
      <div class="right">{{ project.bot_name }}</div>
    </div>
    <div class="block">
      <div class="left">Bot username</div>
      <div class="right">@{{ project.bot_username }}</div>
    </div>
    <div class="block">
      <div class="left">Bot URL</div>
      <div class="right">
        <a :href="`https://t.me/${project.bot_username}`"
          >https://t.me/{{ project.bot_username }}</a
        >
      </div>
    </div>
    <div class="block">
      <div class="left">Project URL</div>
      <div class="right">
        <a :href="project.project_url">{{ project.project_url }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    project() {
      return this.$store.getters['chat/activeProject']
    },
  },
}
</script>

<style lang="scss" scoped>
.block {
  display: grid;
  grid-template-columns: 1.2fr 4fr;
  padding: 0.3rem 0;
  border-bottom: 1px solid transparentize($color: $grayish, $amount: 0.5);
  text-align: left;

  .left {
    font-weight: bold;
  }

  .right {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: all;
  }
}

@media screen and (max-width: 729px) {
  .block {
    grid-template-columns: 3fr 4fr;
  }
}
</style>
