<template>
  <div
    class="chat-message"
    :class="{
      incoming: message.is_incoming,
      'media-message': borderlessTypes.includes(message.message_type),
    }"
  >
    <div class="media">
      <a
        target="_blank"
        v-if="message.message_type == 'photo'"
        :href="attachmentUrl()"
      >
        <img :src="attachmentUrl()" />
      </a>
      <video
        autoplay="true"
        controls="true"
        muted="true"
        v-else-if="message.message_type == 'video'"
      >
        <source :src="attachmentUrl()" type="video/mp4" />
      </video>
      <audio
        controls="true"
        v-else-if="['audio', 'voice'].includes(message.message_type)"
        :src="attachmentUrl()"
      ></audio>
      <template v-else-if="message.message_type === 'document'">
        <img
          v-if="message.attachment.thumbnail_file_id"
          :src="attachmentUrl(message.attachment.thumbnail_file_id)"
        />
        <a class="attachment-box" target="_blank" :href="attachmentUrl()">
          <div class="left">
            <i class="bi bi-paperclip"></i>
          </div>
          <div class="right">
            <div><b>Name:</b> {{ message.attachment.name || '-' }}</div>

            <div>
              <b>Size:</b>
              {{ (message.attachment.size / 1024 / 1024).toFixed(2) }} MB
            </div>
          </div>
        </a>
      </template>
      <template v-else-if="message.message_type === 'location'">
        <img :src="locationImageUrl" alt="location" />
        <a class="attachment-box" target="_blank" :href="locationUrl">
          <div class="left">
            <i class="bi bi-geo-fill"></i>
          </div>
          <div class="right">
            {{ locationUrl }}
          </div>
        </a>
      </template>
    </div>

    <div class="message-body">
      <span class="message-text">{{ messageText }}</span>

      <div class="info">
        <span class="date">{{ getMessageTime(message.created_at) }}</span>
        <span class="agent" v-if="!message.is_incoming">
          <template v-if="message.agent">
            ~ {{ message.agent.full_name }}
          </template>
          <template v-else-if="message.is_automated"> automated </template>
          <!-- sent from the chat ui -->
          <template v-else-if="message.temporary_id">
            ~ {{ $store.state.user.full_name }}
          </template>
        </span>
        <span class="status" v-if="!message.is_incoming">
          <i v-if="message.status === 'received'" class="bi bi-check"></i>
          <i v-else-if="message.status === 'failed'" class="bi bi-x"></i>
          <i
            v-else-if="
              message.status === 'delivered' || message.status === 'read'
            "
            class="bi bi-check-all"
          ></i>

          <i v-else class="bi bi-clock"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getMessageTime } from '@/utils/messageUtils.js'
import { mapGetters } from 'vuex'

export default {
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      borderlessTypes: [
        'location',
        'photo',
        'video',
        'audio',
        'voice',
        'document',
      ],
    }
  },
  computed: {
    ...mapGetters('chat', ['activeProject']),
    encryptedBotToken() {
      return this.activeProject.encrypted_bot_token
    },
    messageText() {
      switch (this.message.message_type) {
        case 'location':
          return 'sent a location'
        case 'dice':
          return `sent a ${this.rawMessage.dice.emoji} with the value ${this.rawMessage.dice.value}`
        case 'contact': {
          let m = 'sent a contact:\n\n'
          m += `Phone number: ${this.rawMessage.contact.phone_number}\n`
          m += `First name: ${this.rawMessage.contact.first_name}\n\n`
          m += `VCard: \n${this.rawMessage.contact.vcard}\n`
          return m
        }
        default:
          break
      }
      return this.message.text || this.message.caption
    },
    rawMessage() {
      return this.message.raw_message
    },
    locationUrl() {
      const lat = this.rawMessage.location.latitude
      const lng = this.rawMessage.location.longitude
      const baseUrl = 'https://www.google.com/maps/search/?api=1'
      const url = `${baseUrl}&query=${lat},${lng}`
      return url
    },
    locationImageUrl() {
      const lat = this.rawMessage.location.latitude
      const lng = this.rawMessage.location.longitude
      const baseUrl =
        'https://static-maps.yandex.ru/1.x/?lang=en-US&l=map&size=600,300'
      const url = `${baseUrl}&ll=${lat},${lng}&z=16&pt=${lng},${lat},pm2rdl`
      return url
    },
  },
  methods: {
    getMessageTime,
    attachmentUrl(attachment_id) {
      if (!attachment_id) {
        attachment_id = this.message.attachment.file_id
      }
      return `/attachment/${this.encryptedBotToken}/${attachment_id}`
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .chat-message {
    max-width: none !important;
  }
}
.chat-message {
  display: inline-block;
  align-self: flex-end;
  padding: 0.4rem 0.7rem 0.4rem;
  margin-bottom: 0.4rem;
  border-radius: 1rem;
  border-bottom-right-radius: 0;
  max-width: 50%;

  background: linear-gradient(
    360deg,
    $alternativeAccentColor,
    $secondaryAccentColor
  );
  color: $textColorDark;

  .info {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.8rem;
    color: $secondaryTextColorDark;

    .date,
    .agent {
      font-size: 0.7rem;
      margin-right: 0.5rem;
    }

    .status img {
      width: 15px;
      height: 15px;
    }
  }

  &.incoming {
    align-self: flex-start;
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    background: $grayishWhite;
    color: $textColor;

    .info {
      color: $secondaryTextColor;
    }
  }

  &.media-message {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    .media {
      img,
      video,
      audio {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        max-width: 100%;
        width: 100%;
        border-radius: 10px 10px 0 0;
        // max-height: 50vh;
        border: 1px solid $secondaryAccentColor;
        border-bottom-width: 0;
      }
    }

    &.incoming {
      .media {
        img,
        video,
        audio {
          border: 1px solid $grayishWhite;
        }
      }
    }

    .message-body {
      padding: 0 0.7rem;
    }
  }

  span {
    white-space: pre-wrap;
    word-break: break-word;
  }

  span.message-text {
    font-size: 0.8rem;
  }

  .attachment-box {
    text-decoration: none;
    color: inherit;
    display: grid;
    grid-template-columns: 1fr 8fr;
    padding: 10px;
    margin: 0 0 0.5rem;
    border-radius: 0.3rem 0.3rem 0 0;
    border-bottom: 1px solid rgba($grayish, 0.7);

    .left {
      .bi {
        font-size: 1.35rem;
      }
    }
    .right {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
