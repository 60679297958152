<template>
  <div id="chat-view">
    <div class="messages" ref="messages" @scroll="scrollMessages">
      <chat-message
        :key="message.id"
        v-for="message in activeChatMessages"
        :message="message"
      />
    </div>
    <div class="editor">
      <chat-editor />
    </div>
    <transition name="fade">
      <div class="loadingSpinner" v-show="isLoadingMessages">
        <i class="bi bi-hourglass-split"></i> Loading...
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ChatEditor from '../../components/chat/ChatEditor.vue'
import ChatMessage from '../../components/chat/ChatMessage.vue'
import _types from '../../store/chat/_types'

export default {
  components: {
    ChatEditor,
    ChatMessage,
  },
  computed: {
    ...mapState('chat', ['isLoadingMessages', 'activeChatId']),
    ...mapGetters('chat', ['activeChatMessages', 'activeChat']),
  },
  methods: {
    ...mapActions('chat', ['loadMoreMessages', 'markChatMessagesAsRead']),
    ...mapMutations('chat', {
      setActiveChatId: _types.SET_ACTIVE_CHAT_ID,
    }),
    async scrollMessages(event) {
      const target = event.target
      let currentPosition = -target.scrollTop + target.clientHeight
      let margin = currentPosition * 0.1 // 10%
      if (currentPosition >= target.scrollHeight - margin) {
        await this.loadMoreMessages(this.activeChatId)
      }
    },
    loadMessages() {
      if (!this.activeChat) return

      // if any of the messages of the active chat is unread
      // mark the chat as read
      // const latestMessage = this.activeChatMessages[0]
      if (this.activeChat.is_unread)
        this.markChatMessagesAsRead(this.activeChat.id)

      // wait until DOM updated with the messages of the new active chat
      this.$nextTick(async () => {
        const chatDiv = this.$refs.messages
        const maxRetries = 10

        // this is for when the scrollbar isn't visible yet
        // try to load more messages to make it visible
        // so auto loading messages when the scrollbar is reached
        // to the top will work
        for (let i = 0; i < maxRetries; i++) {
          if (
            chatDiv.scrollHeight <= chatDiv.clientHeight &&
            this.activeChat.allMessagesLoaded !== true
          ) {
            await this.loadMoreMessages(this.activeChatId)
          }
        }
      })
    },
  },
  watch: {
    activeChat() {
      if (!this.activeChat) return
      this.loadMessages(this.activeChat.id)
    },
  },
  mounted() {
    const chatId = this.$route.params.chatId
    this.setActiveChatId(chatId)
    this.loadMessages(chatId)
  },
  unmounted() {
    if (!this.$route.params.chatId) this.setActiveChatId(null)
  },
}
</script>

<style lang="scss" scoped>
#chat-view {
  display: grid;
  position: relative;
  grid-auto-flow: row;
  grid-template-rows: 8fr minmax(auto, 1fr);
  overflow: hidden;
  background-color: $backgroundColor;
  height: 100%;

  .messages {
    display: flex;
    padding: 10px;
    flex-direction: column-reverse;
    overflow-y: auto;
    // height: 100%;
  }

  .editor {
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: $secondaryBackgroundColor;
  }

  .loadingSpinner {
    text-align: center;
    position: absolute;
    color: #fff;
    z-index: 9;
    background: #5296ff;
    padding: 8px 18px;
    border-radius: 5px;
    left: 45%;
    top: 40%;
    box-shadow: $boxShadow;
  }
}
</style>
