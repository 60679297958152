<template>
  <modal :show="show" @modal-hide="onClose" title="Join now">
    <p>
      Just start a conversation with our
      <a :href="bot.url">@{{ bot.name }}</a> on Telegram to join and create your
      own project in a minute! 😎
    </p>
    <div class="centered my-2">
      <a :href="bot.url" target="_blank" class="join-btn">
        <i class="bi bi-chat-fill"></i>
        Start now
      </a>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  props: {
    show: Boolean,
    onClose: Function,
  },
  data() {
    return {
      showModal: this.show,
    }
  },
  components: { Modal },
  computed: {
    bot() {
      return {
        username: process.env.VUE_APP_TELEGRAM_BOT_USERNAME,
        name: process.env.VUE_APP_TELEGRAM_BOT_NAME,
        url: `https://t.me/${process.env.VUE_APP_TELEGRAM_BOT_USERNAME}`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.join-btn {
  i {
    margin: 0 0.5rem;
  }
}
</style>
