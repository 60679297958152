<template>
  <div class="mega-product-box">
    <div class="top">
      <span class="title">{{ product.name }}</span>
    </div>
    <div class="summary">{{ product.summary }}</div>
    <div class="description" v-html="product.description"></div>
    <div class="prices">
      <template v-if="showPrices">
        <span class="price" :key="price.id" v-for="price in product.prices">
          {{ priceToStr(price) }}
        </span>
        <span class="price" v-if="!product.prices.length">(soon)</span>
      </template>
    </div>
    <a href="javascript:void(0)" class="join-btn" @click="onJoinClick">Join</a>
  </div>
</template>

<script>
import { priceToStr } from '@/utils/priceUtils'
export default {
  props: {
    product: {
      type: Object,
      default: () => Object(),
    },
    showPrices: {
      type: Boolean,
      default: true,
    },
    onJoinClick: {
      type: Function,
    },
  },
  computed: {},
  methods: {
    priceToStr,
  },
}
</script>

<style lang="scss" scoped>
.mega-product-box {
  display: grid;
  grid-template-rows: 50px 70px 300px 100px 50px;
  align-items: center;
  width: 265px;
  text-align: center;
  background: #bcf;
  border-radius: 1rem;
  overflow: hidden;
  font-size: 0.9rem;
  font-family: 'Open Sans', sans-serif;

  .top {
    display: grid;
    align-content: center;
    height: 100%;
    align-self: flex-start;
    background: linear-gradient(
      360deg,
      $alternativeAccentColor,
      $secondaryAccentColor
    );
    color: $textColorDark;
    border-radius: 0.3rem 0.3rem 0 0;

    .title {
      font-weight: bold;
      font-size: 0.95rem;
    }
  }

  .summary {
    align-self: flex-start;
    padding: 0.8rem 1rem 0;
    font-size: 0.906rem;
  }

  .description {
    align-self: flex-start;
    padding: 0 0.5rem;
  }

  .description:deep(ul) {
    list-style-type: none;
    padding: 0;
    margin: 0.5rem 0 0;
  }
  .description:deep(ul li) {
    border-bottom: 1px solid #a3b8f7;
    padding: 0.5rem 0;
  }

  .prices {
    padding: 1rem 0.5rem 0.5rem;
    text-align: center;

    .price {
      // font-weight: bold;
      display: inline-block;
      margin: 0.1rem 0.1rem;
      // text-decoration: underline;
      padding: 0.3rem 0.5rem;
      background: $secondaryAccentColor;
      color: $textColorDark;
      border-radius: 0.5rem;
    }
  }
}

@media screen and (max-width: 729px) {
  .mega-product-box:first-child {
    display: grid;
    grid-template-rows: 50px 70px 200px 100px 50px;
  }
}
</style>
