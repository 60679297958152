<template>
  <div class="landing-box">
    <div
      class="content"
      :class="{ reverse: reverse }"
      :style="{
        'background-color': color,
      }"
    >
      <div class="left">
        <slot />
      </div>
      <div class="right">
        <div class="image">
          <img :class="{ shadow }" :src="getImagePath(image)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'landing-box',
  props: {
    image: {
      type: String,
    },
    color: {
      type: String,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getImagePath(fileName) {
      return require(`@/assets/images/landing/${fileName}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.landing-box {
  .content {
    display: flex;
    gap: 4rem;
    justify-content: space-around;
    margin: 4rem 2rem;

    &.reverse {
      flex-direction: row-reverse;
    }

    .image {
      text-align: center;
      img {
        max-width: 100%;
        border-radius: 10px;
      }
      img[src*='.svg'] {
        max-width: 60%;
      }
    }

    .left {
      flex: 1;

      &:deep(h1) {
        padding: 0;
        margin: 0;
      }
    }
    .right {
      flex: 1;
    }
  }
}

@media screen and (max-width: 729px) {
  .landing-box {
    .content {
      gap: 0.5rem;
      margin: 2rem 1.5rem;
      flex-direction: column !important;
    }
  }
}
</style>
