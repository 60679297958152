<template>
  <div>
    <p style="margin: 0.3rem 0">
      You can edit your project's welcome message here. Use the variables to
      create a customised message. Variables will be replaced with corresponding
      values.
      <button @click="showVariables = !showVariables">Show variables</button>
    </p>
    <div class="variables" v-if="showVariables">
      <span><code>[[full_name]]</code> - User's full name</span>
      <span><code>[[first_name]]</code> - User's first name</span>
      <span><code>[[last_name]]</code> - User's last name</span>
    </div>
    <form @submit.prevent="updateProject" method="post">
      <textarea
        name="welcome_message"
        id="welcome_message"
        rows="4"
        :value="welcomeMessage"
        @input="updateWelcomeMessage"
      >
      </textarea>
      <button type="submit">Update</button>
    </form>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _types from '../../store/chat/_types'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      showVariables: false,
    }
  },
  computed: {
    ...mapGetters('chat', ['activeProject']),
    welcomeMessage() {
      return this.activeProject.welcome_message
    },
  },
  methods: {
    ...mapMutations('chat', [_types.UPDATE_ACTIVE_PROJECT]),
    ...mapActions('chat', ['updateActiveProject']),
    async updateWelcomeMessage(e) {
      this[_types.UPDATE_ACTIVE_PROJECT]({ welcome_message: e.target.value })
    },
    async updateProject() {
      const payload = { welcome_message: this.welcomeMessage }
      this.updateActiveProject(payload)
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  width: 100%;
  height: 100%;
  padding: 0.4rem;
  margin: 0.5rem 0;
  resize: vertical;
  font-size: 0.8rem;
  font-family: sans-serif;
  border-radius: 0.5rem;
  border: none;
}
.variables {
  span {
    display: block;
    code {
      font-size: 1rem;
      user-select: all;
      -moz-user-select: all;
      -webkit-user-select: all;
    }
  }
}
</style>
