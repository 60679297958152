export const SET_CSRF_TOKEN = 'setCSRFToken'
export const SET_USERNAME = 'setUsername'
export const SET_USER = 'setUser'
export const SET_IS_AUTHENTICATED = 'setIsAuthenticated'
export const LOG_OUT = 'logOut'

export default {
  SET_CSRF_TOKEN,
  SET_USERNAME,
  SET_USER,
  SET_IS_AUTHENTICATED,
  LOG_OUT,
}
