<template>
  <nav>
    <router-link class="logo" :to="{ name: 'home' }">
      <img
        class="pointer"
        src="/favicons/apple-icon-180x180.png"
        alt="MossChat Logo"
      />
    </router-link>
    <div class="buttons">
      <template v-if="$store.state.isAuthenticated">
        <!-- autheneticated -->
        <router-link :to="{ name: 'chat-app' }">
          <i class="bi bi-window"></i>
          App
        </router-link>
        <a href="javascript:void(0)" @click.prevent="logOut">
          <i class="bi bi-box-arrow-left"></i>
        </a>
      </template>

      <template v-else>
        <!-- not authenticated -->
        <a href="javascript:void(0)" @click="joinModal = true">
          <i class="bi bi-person-plus"></i>
          Join</a
        >
        <router-link :to="{ name: 'login' }">
          <i class="bi bi-door-open-fill"></i>
          Log in</router-link
        >
      </template>
    </div>
    <join-modal :show="joinModal" :onClose="() => (joinModal = false)" />
  </nav>
</template>

<script>
import api from '../api'
import JoinModal from './JoinModal.vue'

export default {
  name: 'Navbar',
  data() {
    return {
      joinModal: false,
    }
  },
  components: { JoinModal },
  methods: {
    async logOut() {
      if (!confirm("You'll be logged out. Are you sure?")) return
      await api.logOut()
      this.$store.dispatch('chat/clearChatState')
      this.$router.push({ name: 'home' })
    },
  },
  async mounted() {
    await api.getSessionInfo()
  },
}
</script>

<style lang="scss" scoped>
nav {
  display: grid;
  grid-template-columns: 2fr 8fr;
  background: linear-gradient(
    100deg,
    $backgroundColor,
    $secondaryBackgroundColor
  );
  border-radius: 0.5rem;
  height: 60px;
  margin-top: 10px;

  .logo {
    min-height: 0;
    min-width: 0;
    padding: 0.5rem 2rem;

    img {
      height: 40px;
      width: 40px;
    }
  }

  .buttons {
    padding: 0.5rem;
    justify-self: flex-end;
    align-self: center;
    a {
      display: block;
      align-self: flex-start;
      justify-self: center;
      background: linear-gradient(120deg, $textColorDark, $grayish);
      margin: 0 0.5rem;
      border: 0.5px solid $grayish;

      padding: 0.3rem 0.7rem;
      border-radius: 0.3rem;
      color: $textColor;
      text-decoration: none;
      font-weight: bold;
      display: inline-block;
      transition: all 0.3s;

      i.bi {
        margin-right: 0.3rem;
      }
    }

    a:hover {
      opacity: 0.8;
      background: linear-gradient(80deg, $textColorDark, $grayish);
      transform: translatey(2px);
    }
    a:active {
      transform: translatey(4px);
    }
  }
}
</style>
