import types from './_types'
import editorModule from './editor'
import * as chatApi from '../../api/chat'
import router from '@/router'
import api from '../../api'
import { useToast } from 'vue-toastification'

const toast = useToast()

const audios = {
  dinn: new Audio(require('../../assets/audios/dinn.mp3')),
  insight: new Audio(require('../../assets/audios/insight.mp3')),
  knob: new Audio(require('../../assets/audios/knob.mp3')),
  long_expected: new Audio(require('../../assets/audios/long-expected.mp3')),
}

const getInitialChatState = () => {
  return {
    connection: null,
    chats: {},
    messages: {},
    projectId: null,
    activeChatId: null,
    fullscreen: localStorage.getItem('fullscreen') === 'true' ? true : false, // default false
    // fullscreen: localStorage.getItem('fullscreen') === 'false' ? false : true, // default true
    isLoadingMessages: false,
    projects: {},
    status: {
      ok: true,
      message: '',
      code: null,
    },
    bottomStatus: {
      ok: true,
      message: null,
    },
    audios,
  }
}

export default {
  namespaced: true,
  modules: {
    editor: editorModule,
  },
  state: () => getInitialChatState(),
  mutations: {
    [types.SET_PROJECTS](state, payload) {
      let projects = {}
      for (const index in payload) {
        const project = payload[index]
        projects[project.id] = project
      }
      state.projects = projects
    },
    [types.ADD_CHAT_MESSAGES](state, payload) {
      if (payload.messages && !payload.messages.length) return
      const chatId = payload.messages
        ? payload.messages[0].chat_id
        : payload.message.chat_id

      if (payload.messages) {
        return state.messages[chatId].push(...payload.messages)
      }
      state.messages[chatId].unshift(payload.message)
    },
    [types.UPDATE_MESSAGE_STATUS](state, { messageId, chatId, status }) {
      const messages = state.messages[chatId]
      const message = messages.find(
        (m) => m.id === messageId || m.temporary_id === messageId
      )
      message.status = status
    },
    [types.MARK_CHAT_MESSAGES_AS_READ](state, chatId) {
      const messages = [...state.messages[chatId]]
      const newMessages = messages.map((m) => {
        if (m.status === 'delivered') m.status = 'read'
        return m
      })

      const chat = Object.assign({}, state.chats[chatId])
      chat.is_unread = false
      state.chats[chatId] = chat
      state.messages[chatId] = newMessages
    },
    [types.SET_CHAT_MESSAGES](state, payload) {
      const chatId = payload.chatId
      state.messages[chatId] = payload.messages
    },
    [types.SET_CHATS](state, payload) {
      let chats = {}
      for (const index in payload) {
        const chat = payload[index]
        chats[chat.id] = chat
      }
      state.chats = chats
    },
    [types.SET_PROJECT_ID](state, payload) {
      state.projectId = payload
      localStorage.setItem('projectId', payload)
    },
    [types.SET_IS_LOADING_MESSAGES](state, payload) {
      state.isLoadingMessages = payload
    },
    [types.SET_ALL_MESSAGES_LOADED](state, payload) {
      const value = payload.value
      const chatId = payload.chatId
      state.chats[chatId].allMessagesLoaded = value
    },
    [types.SET_ACTIVE_CHAT_ID](state, chatId) {
      state.activeChatId = chatId ?? null
    },
    [types.SET_CONNECTION](state, connection) {
      state.connection = connection
    },
    [types.UPDATE_ACTIVE_PROJECT](state, payload) {
      state.projects[state.projectId] = Object.assign(
        {},
        state.projects[state.projectId],
        payload
      )
    },
  },
  actions: {
    async updateActiveProject({ state }, payload) {
      const projectId = state.projectId
      const url = `/projects/${projectId}/update/`

      try {
        await api.patch(url, payload)
        toast.success('The project has been successfully updated.')
      } catch (data) {
        toast.error(data.message)
      }
    },
    async setActiveChatAndRoute({ commit, state }, chatId) {
      if (!chatId) {
        commit(types.SET_ACTIVE_CHAT_ID, null)
        router.push({
          name: 'chat-app',
          params: { projectId: state.projectId },
        })
      } else {
        commit(types.SET_ACTIVE_CHAT_ID, chatId)
        router.push({
          name: 'project-chat-single',
          params: { chatId: chatId, projectId: state.projectId },
        })
      }
    },
    async setProjects({ commit }, projects) {
      commit(types.SET_PROJECTS, projects)
    },
    async markChatMessagesAsRead({ state, commit }, chatId) {
      await state.connection.markAsRead(chatId)
      commit(types.MARK_CHAT_MESSAGES_AS_READ, chatId)
    },
    async handleNewChat({ state, commit }, chat) {
      const chats = { ...state.chats, [chat.id]: chat }
      commit(types.SET_CHATS, chats)
      commit(types.SET_CHAT_MESSAGES, { chatId: chat.id, messages: [] })
      state.audios.insight.play()
    },
    async handleMessageStatusChange({ state, commit }, announcement) {
      const messageId = announcement.temporary_id || announcement.id
      const messages = state.messages[announcement.chat_id]
      const message = messages.find(
        (m) => m.temporary_id == messageId || m.id == messageId
      )

      if (announcement.message_serialized) {
        commit(types.UPDATE_MESSAGE_STATUS, {
          messageId: messageId,
          chatId: announcement.message_serialized.chat_id,
          status: announcement.message_serialized.status,
        })
      } else {
        message.status = announcement.status
        commit(types.UPDATE_MESSAGE_STATUS, {
          messageId,
          chatId: announcement.chat_id,
          status: announcement.status,
        })
      }
    },
    async handleNewMessage({ state, commit, dispatch }, message) {
      const chatId = message.chat_id
      const chat = state.chats[chatId]
      let actualMessage = state.messages[chatId].find(
        (m) => m.temporary_id && m.temporary_id === message.temporary_id
      )

      if (message.is_incoming) {
        commit(types.ADD_CHAT_MESSAGES, { message })
      } else if (!actualMessage) {
        // message from other agents
        commit(types.ADD_CHAT_MESSAGES, { message })
      } else {
        // message from client's itself
        // TODO: update message with the real message
        actualMessage = message
      }

      if (!message.is_incoming) {
        if (!message.is_automated) chat.is_unread = false
        return
      }

      const isActiveChat = chatId === state.activeChatId
      if (isActiveChat) {
        dispatch('markChatMessagesAsRead', message.chat_id)
      } else {
        // play notification sound if the chat is not active and is incoming
        chat.is_unread = true
        state.audios.insight.play()
      }
    },
    clearChatState({ state }) {
      Object.assign(state, getInitialChatState())
      localStorage.removeItem('projectId')
    },
    clearChatScreenState({ state }) {
      state.status.ok = true
      state.status.message = ''

      state.bottomStatus.ok = true
      state.bottomStatus.message = null
    },
    toggleFullscreen({ state }) {
      state.fullscreen = !state.fullscreen
      localStorage.setItem('fullscreen', state.fullscreen)
    },
    onSocketCreated({ dispatch }) {
      dispatch('clearChatScreenState')
    },
    onSocketClosed({ state }, payload) {
      // if this is for another project connection
      if (!state.connection || state.connection.url != payload.target.url)
        return

      let message = 'The chat connection is closed. Please refresh the page.'

      switch (payload.code) {
        case 4001:
          message = 'You opened a new tab. Please switch to that tab.'
          break
        case 4002:
          message =
            'Please subscribe to Plus or Pro plan to continue using our service.'
          break
        default:
          break
      }
      state.status.ok = false
      state.status.message = message
      state.status.code = payload.code
      state.audios.long_expected.play()

      state.bottomStatus.ok = false
      state.bottomStatus.message = 'Disconnected...'

      const localProjectId = localStorage.getItem('projectId')
      if (!state.projects[localProjectId]) {
        localStorage.removeItem('projectId')
      }
    },
    async getChats({ state, commit }) {
      try {
        const response = await chatApi.getChats(state.projectId)
        const chats = response.results
        for (const index in chats) {
          const chat = chats[index]
          const messages = chat.latest_message ? [chat.latest_message] : []
          commit(types.SET_CHAT_MESSAGES, { chatId: chat.id, messages })
        }
        commit(types.SET_CHATS, chats)
      } catch (error) {
        console.log(error)
      }
    },
    async loadMoreMessages({ state, commit }, chatId) {
      const chat = state.chats[chatId]
      if (state.isLoadingMessages || chat.allMessagesLoaded) return

      // set loading messages to true
      commit(types.SET_IS_LOADING_MESSAGES, true)

      // get messages
      const data = await chatApi.getChatMessages(
        chat.id,
        state.projectId,
        state.messages[chat.id].length
      )
      const messages = data.results

      commit(types.ADD_CHAT_MESSAGES, { messages })
      commit(types.SET_IS_LOADING_MESSAGES, false)
      commit(types.SET_ALL_MESSAGES_LOADED, {
        chatId: chat.id,
        value: !data.next,
      }) // no next page = last page
    },
  },
  getters: {
    activeProject: (state) => state.projects[state.projectId] || {},
    activeChat: (state) => state.chats[state.activeChatId],
    activeChatMessages: (state) => state.messages[state.activeChatId],
    isCreator: (_, getters, rootState) => {
      return (
        getters.activeProject.creator_telegram_id === rootState.user.telegram_id
      )
    },
    orderedChats: (state) => {
      return Object.values(state.chats).sort((a, b) => {
        const messagesA = state.messages[a.id]
        const messagesB = state.messages[b.id]

        if (!messagesB || !messagesB.length) return false
        const date1 = new Date(messagesA[0].created_at)
        const date2 = new Date(messagesB[0].created_at)
        return date1 < date2
      })
    },
  },
}
