<template>
  <div class="product-box-container">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.product-box-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  h3 {
    text-align: center;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 729px) {
  .product-box-container {
    flex-direction: column;

    & > .mega-product-box {
      // width: 70% !important;
      margin: 0 auto;
    }
  }
}
</style>
