const getMessageTime = (messageDate, showTime = false) => {
  let options = { hour: '2-digit', minute: '2-digit' }
  let today = new Date()
  let d = new Date(messageDate)
  if (
    d.getDate() == today.getDate() &&
    d.getMonth() == today.getMonth() &&
    d.getFullYear() == today.getFullYear()
  ) {
    return d.toLocaleTimeString([], options)
  }
  options = {
    ...options,
    ...{ year: 'numeric', month: 'numeric', day: 'numeric' },
  }
  if (showTime === true) {
    return d.toLocaleString([], options)
  }
  return d.toLocaleDateString([], options)
}

export { getMessageTime }
