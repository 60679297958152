<template>
  <div class="landing">
    <div class="jumbotron">
      <div class="content">
        <h1>Customer support via Telegram Bot</h1>
        <h2>Meet moss.chat!</h2>
        <p>
          Build your own Telegram chatbot in a minute and turn it into a fully
          functional and production ready customer support system! Let moss.chat
          power your communication with your customers around the world, so you
          can connect with them on Telegram simply, securely and reliably.
        </p>
      </div>
      <div class="image">
        <img class="shadow" :src="mainImageUrl" />
      </div>
    </div>

    <div class="simple-box">
      <h2 class="header">Who is moss.chat for?</h2>
      <div class="landing-list">
        <ul>
          <li :key="reason" v-for="reason in reasons">{{ reason }}</li>
        </ul>
      </div>
    </div>

    <div class="simple-box">
      <h2 class="header">Discover</h2>
      <p>See some moss.chat features now!</p>
    </div>
    <LandingBox image="home-5-opt.png">
      <div class="centered">
        <img
          style="max-width: 50%"
          src="@/assets/images/landing/online_chat.svg"
          alt=""
        />
      </div>
      <h1>Easy access from anywhere</h1>
      <h2>Answer on any device, anywhere, anytime</h2>
      <p>
        moss.chat not only provides you an elegant web UI for managing chats
        with your customers on any devices. You can also answer messages on
        official Telegram clients! With a single chatbot, you have everything
        you need!
      </p>
      <h3>Isn't it so cool? 🤟😎</h3>
    </LandingBox>
    <LandingBox image="home-2-opt.png" reverse shadow>
      <h1>Your team, your power</h1>
      <h2>Add as many agents as you want!</h2>
      <p>
        Why do all alone? Your team can help you answer messages on moss.chat or
        Telegram! They can log in to the project dashboard, and be part of your
        business!
      </p>
    </LandingBox>
    <LandingBox image="chat-5.png" shadow>
      <h1>Different projects for different organizations</h1>
      <h2>And all in the same place!</h2>
      <p>
        You can create as many projects as you want and all will be isolated
        from each other. Every project represents a different chat bot and you
        can manage all your projects in the same place with moss.chat!
      </p>
    </LandingBox>
    <div class="did-you-know">
      <h3>Did you know that...</h3>
      <ul>
        <li>
          52% of respondents have switched brands/service provider because of
          poor customer service (Accenture)
        </li>
        <li>
          89% of respondents expect a brand to respond to them within 24 hours.
          (SAP Hybris)
        </li>
        <li>
          80% of respondents say that the experience a business provides is as
          crucial as its goods or services (Salesforce)
        </li>
      </ul>
    </div>

    <div class="simple-box">
      <h2 class="header">Some other features</h2>
      <div class="landing-list compact centered">
        <ul>
          <li :key="feature" v-for="feature in features">✅ {{ feature }}</li>
        </ul>
        <p>
          <small
            >*Some features are available for a specific subscription
            plan.</small
          >
        </p>
      </div>
    </div>

    <div class="simple-box">
      <h2 class="header">Join now for free</h2>
      <p class="centered">
        You can try moss.chat with a free 1-day trial. No credit card required!
        🤩
      </p>

      <mega-product-box-container>
        <mega-product-box
          :key="product.id"
          v-for="product in megaProducts"
          :product="product"
          :onJoinClick="() => (joinModal = true)"
        />
      </mega-product-box-container>
    </div>
  </div>
  <join-modal :show="joinModal" :onClose="() => (joinModal = false)" />
</template>

<script>
import LandingBox from '@/components/LandingBox.vue'
import MegaProductBox from '@/components/MegaProductBox.vue'
import MegaProductBoxContainer from '@/components/MegaProductBoxContainer.vue'

import api from '../api'
import JoinModal from '../components/JoinModal.vue'

export default {
  name: 'Home',
  components: {
    LandingBox,
    MegaProductBox,
    MegaProductBoxContainer,
    JoinModal,
  },
  data() {
    return {
      products: [],
      joinModal: false,
      mainImageId: 1,
      features: [
        'Unsend a message',
        'Custom welcome messages with custom variables',
        'Delivery reports',
        'Mobile-friendly design for all devices',
        'Fullscreen mode',
        'Chat sounds',
        'Instantly visible simultaneous messages for all team members',
        'Know which agent sent the message',
        'Get the same message on both Telegram and our web UI and reply anywhere',
        'Message templates (very soon!)',
        'Fair and partial pricing',
      ],
      reasons: [
        '💸 You sell products or services on Telegram and need to talk to your customers',
        '🏢 You have a startup, organization or a project with a team and you want them all to be able to communicate with your target audience at the same time using a fully-featured and simple customer support system based on Telegram.',
        "👥 You have a large Telegram community (group/channel). Especially if you can't answer everyone's questions alone and you need your own team/agents to help you.",
        '🕵️‍♀️ You have a group where you hide your identity using anonymous admins feature but want to talk to your members.',
        '🤖 You have many bots with a great username and you want people to reach you easily using that bot.',
        // '🤴 You are a special person and you want people to talk to you without knowing your Telegram account.',
        '👤 You want anyone to be able to talk with you on Telegram, but don’t feel comfortable enough to share your Telegram link/username on the internet.',
        '🆘 You need an innovative support system that separates different projects or business but allows you to access them all from the same place.',
      ],
    }
  },
  computed: {
    mainImageUrl() {
      return require(`@/assets/images/landing/home-${this.mainImageId}-opt.png`)
    },
    megaProducts() {
      return this.products
        .filter((p) => p.is_mega)
        .sort(
          (a, b) => a.prices.length && a.prices[0].amount > b.prices[0].amount
        )
    },
  },
  methods: {
    async getProducts() {
      try {
        const products = await api.get('/subscriptions/products/')
        this.products = products
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    this.getProducts()
    setInterval(() => {
      this.mainImageId = this.mainImageId === 1 ? 0 : 1
    }, 3000)
  },
}
</script>

<style lang="scss" scoped>
.landing {
  font-family: 'Montserrat', sans-serif;
  margin: 2rem 0;

  .header {
    text-align: center;
    font-size: 2rem;
    margin: 4rem 0 1rem;
    position: relative;
    padding-bottom: 1rem;

    &:before {
      content: '';
      border-bottom: 2px solid $borderColor;
      width: 50%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 25%;
    }
  }

  img {
    max-width: 100%;
  }

  .jumbotron {
    text-align: center;

    .content {
      max-width: 800px;
      margin: 5rem auto 3rem;
      padding: 0 0.5rem;
    }
    img {
      border-radius: 10px;
      max-width: 80%;
    }
  }
}

.simple-box {
  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0.3rem 0;
  }
  p {
    text-align: center;
    font-size: 1rem;
    margin: 0 0 2rem;
  }
}

.landing-list {
  max-width: 75%;
  margin: 0 auto;
  padding: 0 0.5rem;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid $borderColor;
      padding: 0.8rem 0;
    }
  }

  &.compact {
    max-width: 50%;
    text-align: left;

    ul li {
      padding: 0.3rem 0;
      border-color: transparentize($borderColor, 0.5);
    }

    p {
      margin: 0.5rem 0;
    }
  }
}

.did-you-know {
  border: 1px solid #eee;
  border-radius: 10px;
  background: linear-gradient($backgroundColor, $alternativeBackgroundColor);
  max-width: 800px;
  padding: 1rem;
  margin: 0 auto;

  h3 {
    text-align: center;
  }

  ul {
    list-style-type: 'ℹ️ ';

    li {
      margin: 0.5rem 0;
    }
  }
}

@media screen and (max-width: 729px) {
  .landing-list {
    max-width: 100%;
    margin: 0 auto;

    &.compact {
      max-width: 100%;
      text-align: left;
    }
  }
}
</style>
