<template>
  <div
    class="product-box noselect"
    :class="{ activeProduct }"
    @click="expanded = !expanded"
    :style="{ height: expanded ? 'auto' : '5rem' }"
  >
    <div class="left">
      <h4 class="title">
        {{ product.name }}
        <span class="units" v-if="product.quantity > 1"
          >x{{ product.quantity }} units</span
        >
      </h4>
      <p v-if="!expanded" class="summary ellipsis">
        {{ expanded ? product.description : product.summary }}
      </p>
      <p v-else class="summary" v-html="product.description"></p>
    </div>
    <div class="right">
      <template v-if="showBuyButton">
        <template
          v-if="
            !subscribed &&
            product.parent_products.length &&
            !subscribedParents.length
          "
        >
          <div style="font-size: 0.7rem">
            Exclusive to
            <span :key="pp.id" v-for="(pp, index) in product.parent_products">
              <b class="exclusive-product-name">{{ pp.name }}</b>
              <span v-if="index + 1 < product.parent_products.length">, </span>
            </span>
          </div>
        </template>

        <button
          v-else
          class="price-btn"
          :key="price.id"
          v-for="price in expanded ? product.prices : [product.prices[0]]"
          @click.stop="createCheckoutSession(price.id)"
        >
          <i class="bi bi-cart-plus bi-small"></i>
          {{ priceToStr(price) }}
        </button>
        <span v-if="!product.prices.length">(soon)</span>
      </template>
      <template v-else>
        <small v-if="!subscription.status">
          Will be cancelled on
          {{ new Date(subscription.current_period_end).toLocaleDateString() }}
        </small>
        <p v-else>Active</p>
      </template>
    </div>
  </div>
</template>

<script>
import api from '../api'
import { useToast } from 'vue-toastification'
import { priceToStr } from '@/utils/priceUtils'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  props: {
    product: {
      type: Object,
      default: () => Object(),
    },
    subscription: {
      type: Object,
      default: () => Object(),
    },
    showBuyButton: {
      type: Boolean,
      default: true,
    },
    activeProduct: {
      type: Boolean,
      default: false,
    },
    subscribed: Boolean,
    subscribedParents: Array,
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {},
  methods: {
    priceToStr,
    async createCheckoutSession(priceId) {
      try {
        const projectId = this.$store.state.chat.projectId
        const r = await api.post('/subscriptions/checkout-session/create/', {
          priceId: priceId,
          projectId: projectId,
        })
        window.location.href = r.checkout_url
      } catch (data) {
        if (data.status === 303) {
          return this.toast.info(data.message)
        }
        this.toast.error(data.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.product-box {
  display: grid;
  grid-template-columns: 4fr 1fr;
  // column-gap: 10%;
  align-items: center;
  border: 2px solid $alternativeBorderColor;
  background-color: $backgroundColor;
  border-radius: 0.5rem;
  padding: 0.5rem;
  height: 5rem;
  overflow: hidden;
  margin: 0.5rem 0;
  cursor: pointer;
  transition: all 0.3s;
  color: $textColor;

  .price-btn {
    font-size: 0.75rem;
    border: 1px solid $alternativeBorderColor;
    border-left: 3px solid $accentColor;
    background: linear-gradient($backgroundColor, $alternativeBackgroundColor);
    box-shadow: 2px 2px 3px $alternativeBorderColor;
    color: $textColor;
    padding: 0.2rem 0.29rem;
    border-radius: 0 0.3rem 0.3rem 0;
    cursor: pointer;

    &:hover {
      background: $alternativeBackgroundColor;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  &.activeProduct {
    border-color: $accentColor;
  }

  &:hover {
    background-color: $alternativeBackgroundColor;
  }

  .exclusive-product-name {
    display: inline-block;
    border: 1px solid $accentColor;
    padding: 0px 2px;
    border-radius: 5px;
    background: $accentColor;
    white-space: nowrap;
    margin: 2px 0;
    font-size: 0.6rem;
  }

  .left {
    overflow: hidden;
    .title {
      margin: 0;

      .units {
        background-color: $accentColor;
        border-radius: 0.3rem;
        padding: 0.1rem 0.3rem;
        display: inline-block;
        font-size: 0.7rem;
      }
    }
    .summary {
      margin: 0.3rem 0;

      &:deep(ul) {
        margin: 0;
        padding: 0 1.5rem;
        list-style-type: circle;
      }
    }
  }

  .right {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .product-box {
    // grid-template-columns: 1fr;
  }
}
</style>
