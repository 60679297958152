<template>
  <div style="text-align: center">
    <div class="alert error">404 - page not found</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
