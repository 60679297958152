<template>
  <footer>
    <p><b>moss.chat</b></p>
    <p>Copyright &copy; 2021 - {{ new Date().getFullYear() }}</p>
    <ul>
      <li>
        <router-link :to="{ name: 'privacy' }">Privacy </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'terms' }"> Terms of Use</router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
footer {
  text-align: center;
  margin: 1rem 0;
  padding: 1rem;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}
</style>
