export const ADD_CHAT_MESSAGES = 'ADD_CHAT_MESSAGES'
export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES'
export const SET_TEXT = 'SET_TEXT'
export const SET_PLACEHOLDER = 'SET_PLACEHOLDER'
export const SET_CHATS = 'SET_CHATS'
export const SET_PROJECT_ID = 'SET_PROJECT_ID'
export const SET_PROJECTS = 'SET_PROJECTS'
export const SET_ACTIVE_CHAT_ID = 'SET_ACTIVE_CHAT_ID'
export const SET_IS_LOADING_MESSAGES = 'SET_IS_LOADING_MESSAGES'
export const SET_ALL_MESSAGES_LOADED = 'SET_ALL_MESSAGES_LOADED'
export const SET_CONNECTION = 'SET_CONNECTION'
export const SET_FILES = 'SET_FILES'
export const UPDATE_MESSAGE_STATUS = 'UPDATE_MESSAGE_STATUS'
export const MARK_CHAT_MESSAGES_AS_READ = 'MARK_CHAT_MESSAGES_AS_READ'
export const UPDATE_ACTIVE_PROJECT = 'UPDATE_ACTIVE_PROJECT'

export default {
  ADD_CHAT_MESSAGES,
  SET_CHAT_MESSAGES,
  SET_TEXT,
  SET_PLACEHOLDER,
  SET_CHATS,
  SET_PROJECT_ID,
  SET_PROJECTS,
  SET_ACTIVE_CHAT_ID,
  SET_IS_LOADING_MESSAGES,
  SET_ALL_MESSAGES_LOADED,
  SET_CONNECTION,
  SET_FILES,
  UPDATE_MESSAGE_STATUS,
  MARK_CHAT_MESSAGES_AS_READ,
  UPDATE_ACTIVE_PROJECT,
}
