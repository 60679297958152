<template>
  <div class="centered my-2">
    <div v-if="projectId">
      <div class="alert success">
        The project you want to log in for was detected successfully. Use the
        button below to log in.
      </div>
    </div>
    <div v-else>
      <div class="alert info">
        It looks like you didn't use a log in URL of a specific project. Don't
        worry, we'll try to find projects you are a member of.
      </div>
    </div>
    <div id="login-widget" v-show="!$store.state.isAuthenticated"></div>
  </div>
</template>

<script>
import api from '../api'
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      projectId: null,
    }
  },
  methods: {
    getRedirectUrl() {
      if (this.$route.query.nextUrl) {
        return this.$route.query.nextUrl
      }
      if (this.projectId) {
        return { name: 'project-chat', params: { projectId: this.projectId } }
      }
      return '/'
    },
    async onTelegramAuth(user) {
      try {
        user.projectId = this.projectId
        await api.post('/users/login/', user)
        await api.setCSRF()
        await api.getSessionInfo()
        this.toast.success("You've successfully logged in!")
        this.$router.push(this.getRedirectUrl())
      } catch (error) {
        if (error.status === 401) {
          this.toast.info(error.message, { timeout: 10000 })
          return
        }
        this.toast.error(error.message)
      }
    },
    async mountTelegramLoginWidget() {
      window.onTelegramAuth = this.onTelegramAuth

      const telegramWidget = document.createElement('script')
      telegramWidget.async = true
      const attrs = {
        src: 'https://telegram.org/js/telegram-widget.js?15',
        'data-onauth': 'window.onTelegramAuth(user)',
        'data-telegram-login': process.env.VUE_APP_TELEGRAM_BOT_USERNAME,
        'data-size': 'medium',
        'data-radius': 5,
        'data-request-access': 'write',
      }
      for (const attr in attrs) {
        const data = attrs[attr]
        telegramWidget.setAttribute(attr, data)
      }
      const container = document.getElementById('login-widget')
      container.appendChild(telegramWidget)
    },
  },
  async mounted() {
    const query = Object.assign({}, this.$route.query)
    if (query.id && query.hash) {
      delete query.nextUrl
      await this.onTelegramAuth(query)
      return
    }

    this.projectId =
      localStorage.getItem('projectId') ?? this.$store.state.chat.projectId
    localStorage.removeItem('projectId')
    await this.mountTelegramLoginWidget()
  },
}
</script>

<style lang="scss" scoped>
nav {
  background-color: $secondaryBackgroundColor;
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;

  #login-widget {
    display: inline-block;
    vertical-align: middle;
  }

  a {
    font-weight: bold;
    color: #555;
    margin: 0 0.5rem;
    background: #ddd;
    padding: 0.3rem 0.9rem;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.2s;
  }

  a:hover {
    background: #ccc;
  }
}
</style>
