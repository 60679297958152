import { createStore, createLogger } from 'vuex'
import chat from './chat'
import types from './_types'

const store = createStore({
  plugins: [createLogger()],
  namespaced: true,
  state: () => ({
    CSRFToken: '',
    username: '',
    isAuthenticated: false,
    user: {},
  }),
  mutations: {
    [types.SET_CSRF_TOKEN](state, CSRFToken) {
      state.CSRFToken = CSRFToken
    },
    [types.SET_USER](state, user) {
      state.user = user
      if (!user) localStorage.removeItem('user')
      else localStorage.setItem('user', user)
    },
    [types.SET_IS_AUTHENTICATED](state, isAuthenticated) {
      isAuthenticated = isAuthenticated === true
      state.isAuthenticated = isAuthenticated
      localStorage.setItem('isAuthenticated', isAuthenticated)
    },
  },
  actions: {
    [types.SET_CSRF_TOKEN]({ commit }, payload) {
      commit(types.SET_CSRF_TOKEN, payload)
    },
    [types.SET_USERNAME]({ commit }, payload) {
      commit(types.SET_USERNAME, payload)
    },
    [types.SET_USER]({ commit }, payload) {
      commit(types.SET_USER, payload)
    },
    [types.SET_IS_AUTHENTICATED]({ commit }, payload) {
      commit(types.SET_IS_AUTHENTICATED, payload)
    },
    [types.LOG_OUT]({ commit }) {
      commit(types.SET_IS_AUTHENTICATED, false)
      commit(types.SET_USER)
    },
  },
  modules: {
    chat,
  },
})

export default store
