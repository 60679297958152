import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import PageNotFound from '../views/404.vue'
import Login from '../components/Login.vue'
import ProjectIndex from '../views/ChatApp/ProjectIndex.vue'
import ProjectSettings from '../views/ChatApp/ProjectSettings.vue'
import ProjectChatSingle from '../views/ChatApp/ProjectChatSingle.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { guest: true },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { guest: true },
  },
  {
    path: '/app',
    name: 'chat-app',
    meta: { protected: true },
    // this generates a separate chunk (_app.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "chatApp" */ '../views/ChatApp/ProjectChat.vue'
      ),
    children: [
      {
        path: ':projectId',
        name: 'project-chat',
        redirect: (to) => ({
          name: 'chat-app',
          params: { projectId: to.params.projectId },
        }),
        component: ProjectIndex,
        children: [
          {
            path: 'settings',
            name: 'project-settings',
            component: ProjectSettings,
            meta: { title: 'Project Settings' },
          },
          {
            path: 'chat/:chatId',
            name: 'project-chat-single',
            component: ProjectChatSingle,
            // meta: { title: '' },
          },
        ],
      },
    ],
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/Privacy.vue'), // for now
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true'
  if (to.params.projectId) {
    // save projectId to use in log in page
    // because /app/:projectId redirects to /app before redirecting to /login
    // so the projectId gets lost
    localStorage.setItem('projectId', to.params.projectId)
  }
  if (to.matched.some((r) => r.meta.protected)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isAuthenticated) {
      return next({
        path: '/login',
        params: { projectId: to.params.projectId },
        query: { nextUrl: to.fullPath },
      })
    }
  } else if (to.matched.some((r) => r.meta.guest)) {
    if (isAuthenticated) return next({ name: 'chat-app' })
  }
  next() // make sure to always call next()!
})

export default router
